'use client';

import styled, { css } from 'styled-components';

import { Container } from '@whiteaway/ui';

import { ErrorBanner } from '@/components';

interface Props extends React.PropsWithChildren {}

/**
 * The component holds the content (container) for the protected part of the app.
 */
export const ProtectedContent: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <>
      <ErrorBanner />
      <Content>{children}</Content>
    </>
  );
};

const Content = styled(Container)`
  ${({ theme }) => css`
    display: flex;
    flex: 1;

    padding-top: ${theme.getSpacing(6)};
    padding-bottom: ${theme.getSpacing(6)};
  `}
`;
