'use client';

import React from 'react';
import styled from 'styled-components';

import { Stack } from '@whiteaway/ui';

interface Props extends React.PropsWithChildren {}

/**
 * The component holds the root element for the protected part of the app.
 */
export const ProtectedRoot: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Root direction="column" spacing={0}>
      {children}
    </Root>
  );
};

const Root = styled(Stack)`
  min-height: 100vh;
`;
